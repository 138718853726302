import {Source} from "../../types/DataSource.ts";
import ads from "../../../assets/brands/ads_logo.png";
import Amazon from "../../../assets/brands/amazon.svg";
import cin7 from "../../../assets/brands/cin7_logo.png";
import MuffinData from "../../../assets/brands/muffin_data.svg";
import Netsuite from "../../../assets/brands/netsuite.svg";
import Quickbooks from "../../../assets/brands/quickbooks.svg";
import Shopify from "../../../assets/brands/shopify.svg";
import TikTok from "../../../assets/brands/tiktok.png";
import Trackstar from "../../../assets/brands/trackstarhq_logo.jpeg";
import Walmart from "../../../assets/brands/walmart_logo.png";
import WholeFoods from "../../../assets/brands/whole_foods_logo.png";
import Dp from "../../../assets/drivepoint.png";

type DataSource = {
  id: Source,
  name: string,
  icon: string,
  status?: string,
  refreshedAt?: string
};

const dataSourcesMap: Record<Source, DataSource>  = {
  shopify: {id: "shopify", name: "Shopify", icon: Shopify},
  netsuite: {id: "netsuite", name: "Netsuite", icon: Netsuite},
  quickbooks: {id: "quickbooks", name: "Quickbooks", icon: Quickbooks},
  amazon: {id: "amazon", name: "Amazon Seller", icon: Amazon},
  drivepoint_ecommerce: {id: "drivepoint_ecommerce", name: "Drivepoint E-Commerce", icon: Dp},
  muffindata: {id: "muffindata", name: "Muffin Data", icon: MuffinData},
  walmart: {id: "walmart", name: "Walmart", icon: Walmart},
  wholefoods: {id: "wholefoods", name: "Whole Foods", icon: WholeFoods},
  tiktok: {id: "tiktok", name: "TikTok Shop", icon: TikTok},
  "cin7": {id: "cin7", name: "CIN7", icon: cin7},
  "cin7core": {id: "cin7core", name: "CIN7 Core", icon: cin7},
  "cin7omni": {id: "cin7omni", name: "CIN7 Omni", icon: cin7},
  "trackstar": {id: "trackstar", name: "Trackstar", icon: Trackstar},
  "custom": {id: "custom", name: "Custom", icon: Dp},
  ads: {id: "ads", name: "Monthly Ads", icon: ads}
};
export default dataSourcesMap;
